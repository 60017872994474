import {createRouter, createWebHistory} from 'vue-router';
import WrapperPage from "@/pages/router/WrapperPage.vue";
import TeacherPage from "@/pages/personal/TeacherPage.vue";
import LandingPage from "@/pages/LandingPage.vue";
import ActionPage from "@/pages/ActionPage.vue";
// import VideoPage from "@/pages/VideoPage.vue";
import PresentationPage from "@/pages/PresentationPage.vue";
import CertificatePage from "@/pages/CertificatePage.vue";
import StudentLoginPage from "@/pages/authorization/StudentLoginPage";
import ProfilePage from "@/pages/personal/ProfilePage";
import MissionsPage from "@/pages/personal/MissionsPage";
import MissionPassagePage from "@/pages/personal/MissionPassagePage";
import StatisticsPage from "@/pages/personal/StatisticsPage";
import AssistantPage from "@/pages/personal/AssistantPage";
import {
    allMissionPassed, canEnterLanding,
    canEnterMission,
    canEnterToAuthorization, canEnterToPayment, canEnterToSuccessPayment,
    canEnterToUserPortal
} from "@/router/helpers";
import StudentRegistrationPage from "@/pages/authorization/StudentRegistrationPage";
import MissionRouter from "@/pages/router/MissionRouter";
import VideoBrowserPage from "@/pages/VideoBrowserPage.vue";
import PaymentEndPage from "@/pages/PaymentEndPage.vue";
import PaymentEndPageGood from "@/pages/PaymentEndPageGood.vue";
// import PngPage from "@/pages/SharePostPreview.vue";


// creating routes to all pages
const routes = [
    {
        path: '/',
        name: 'landing',
        component: LandingPage,
        beforeEnter: async () => {
            await canEnterLanding();
        }
    },
        {
        path: '/action',
        name: 'action',
        component: ActionPage,
        beforeEnter: async () => {
            await canEnterLanding();
        }
    },
    // {
    //     path: '/png',
    //     name: 'png',
    //     component: PngPage,
    // },
    {
        path: '/certificate',
        name: 'certificate',
        component: CertificatePage,
    },
    // {
    //     path: '/video/:token',
    //     name: 'video',
    //     props: true,
    //     component: VideoPage,
    // },
    {
        path: '/presentation',
        name: 'presentation',
        component: PresentationPage,
    },
    {
        path: '/authorization',
        name: 'authorization',
        component: StudentLoginPage,
        beforeEnter: async (to, from, next) => {
            await canEnterToAuthorization(next, to);
        }
    },
    {
        path: '/registration',
        name: 'registration',
        component: StudentRegistrationPage,
        beforeEnter: async (to, from, next) => {
            return await canEnterToAuthorization(next, to);
        }
    },
    {
        path: '/cabinet',
        name: 'cabinet',
        component: WrapperPage,
        children: [
            {
                path: 'profile',
                name: 'profile',
                component: ProfilePage
            },
            {
                path: 'teacher',
                name: 'teacher',
                component: TeacherPage
            },
            {
                path: 'statistics',
                name: 'statistics',
                component: StatisticsPage,
                beforeEnter: (to, from, next) => {
                    if (!allMissionPassed()) next({name: 'missions'});
                    else next();
                }
            },
            {
                path: 'missions',
                name: 'missions',
                component: MissionsPage,
                beforeEnter: (to, from, next) => {
                    if (allMissionPassed()) next({name: 'statistics'});
                    else next();
                }
            },
            {
                path: 'assistant',
                name: 'assistant',
                component: AssistantPage,
            },

        ],
        beforeEnter: async (to, from, next) => {
            await canEnterToUserPortal(next, to);
        }
    },
    {
        path: "/payment",
        name: 'PaymentEndPage',
        component: PaymentEndPage,
        beforeEnter: async (to, from, next) => {
            await canEnterToPayment(next, to, from);
        }
    },
    {
        path: "/payment/success",
        name: 'paymentSuccess',
        component: PaymentEndPageGood,
        beforeEnter: async (to, from, next) => {
            await canEnterToSuccessPayment(next,from);
        }
    },
    {
        path: '/missions',
        name: 'missionsRouter',
        component: MissionRouter,
        children: [
            {
                path: 'pass/:missionId',
                name: 'missionPass',
                props: true,
                component: MissionPassagePage,
                beforeEnter: canEnterMission
            },
        ]
    },
    {
        path: '/video/:userId?',
        name: 'videoBrowser',
        props: true,
        component: VideoBrowserPage
    },

    {
        path: "/:pathMatch(.*)*",
        name: '404',
        beforeEnter: (to, from, next) => {
            next({name: 'authorization'});
        }
    },

];

// creating Vue-Router instance with normal history mode
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: +100
            }
        } else {
            return {top: 0, left: 0};
        }
    }
});

export default router;
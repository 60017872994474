<template>
  <main class="action">
    <div class="action-fill">
      <img src="@/assets/imgs/action-robot.png" alt="" class="action__img">
      <div class="content">
        <p class="title">Здравствуйте!  </p>
        <p class="subtitle">Вы зашли на страницу подачи заявок на использование сервиса <span>«Цифровой помощник для выбора профессии»</span>. <br><br>
Возможность оставить данные о количестве участников и забронировать доступы будет доступна с 14 по 21 апреля 2025 г.
        </p>
        <p class="text"> <span> Для этого представителю образовательной организации будет необходимо:</span> <br>                                                                                            
          📌пройти регистрацию и достоверно заполнить все поля с данными;<br>                                                                                                                               
          📌выбрать нужное вам количество кодов доступа в соответствии с количеством учащихся, которые будут использовать сервис;<br>                                                             
          📌указать адрес действующей электронной почты, на которую вы хотите получить коды;<br>                                                                          
          📌забронировать необходимое вам количество кодов доступа;<br>  
          📌получить забронированные коды доступа на указанный электронный адрес после завершения процесса бронирования.<br>
        </p>
        <p class="text"> <span> Важная информация: </span> <br>       
📌один представитель образовательной организации за одно заполнение формы подачи заявок сможет получить до 30 кодов доступа, но при этом он может подавать заявку неограниченное количество раз (например: в 1-й раз – вы запросили 20 доступов, во 2-й раз – 30 и т. д.);<br>
📌каждый код доступа — это уникальный набор символов, который можно использовать только один раз, то есть только один учащийся сможет им воспользоваться для использования сервиса;<br>                                                                         
📌кодом доступа сможет воспользоваться учащийся, рождённый ранее 2013 года (включительно, но не младше);<br>                                                                                               
📌если вы бронируете несколько кодов доступа, то на указанный электронный адрес вы получите то количество кодов, которое указали;<br>                                                      
📌после получения кода доступа учащийся, которому вы предоставите этот код, сможет зайти по ссылке - <a href="https://цифровойпомощник.рф" target="_blank">https://цифровойпомощник.рф</a> - на наш сервис и воспользоваться им;<br>                                            
📌с более подробной информацией об использовании кода доступа вы сможете ознакомиться в инструкции, которую мы вам направим после завершения процесса бронирования; <br> 
📌если ты – ученик, то проходи регистрацию по основной ссылке на наш сервис;<br>
📌обращаем ваше внимание, что полученные коды доступа будут действительны до 30 мая 2025 года.      <br>
        </p>
        <p class="text">Если у вас есть вопрос, напишите его, пожалуйста, на наш электронный адрес: <a href="mailto:tsip.rf@mail.ru" target="_blank">tsip.rf@mail.ru</a></p>
      </div>
    </div>

  </main>
</template>

<script>

export default {
  name: "PageWrapper",
};
</script>

<style scoped>
main {
  background: #fff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 16px;
}

* {
  font-family: "PT Root UI", sans-serif;
  line-height: 140%;
}

.action-fill {
  display: flex;
  align-items: center;
  max-width: 1256px;
  width: 100%;
  margin: 0 auto;
  border: 4px solid #ad4fea;
  border-radius: 56px;
  align-items: flex-start;
  gap: 65px;
  padding: 38px 53px 38px 39px;
  background: #fff;
}

.action__img {
  max-width: 408px;
  width: 100%;
  position: sticky;
  top: 20px;
}

.content {
  max-width: 100%;
  width: 100%;
}

.title {
  font-weight: 700;
  font-size: 48px;
  color: #000;
}

.subtitle {
  margin-top: 40px;
  font-weight: 400;
  font-size: 32px;
  color: #000;
}

.subtitle span {
  font-weight: 700;
}

.text {
  margin-top: 25px;
  font-weight: 400;
  font-size: 24px;
  color: #000;
}
.text span{
  font-weight: 600;
}
.text a {
  font-weight: 700;
  color: #4a58dd;
}

@media (max-width: 800px) {
  .action-fill {
    border: 2px solid #ad4fea;
    border-radius: 20px;
    align-items: center;
    gap: 30px;
    padding: 20px;
    flex-direction: column;
  }

  .action__img {
    max-width: 208px;
    position: relative;
    top: unset;
  }

  .title {
    font-size: 32px;
  }

  .subtitle {
    margin-top: 20px;
    font-size: 24px;
  }

  .text {
    margin-top: 16px;
    font-weight: 400;
    font-size: 18px;
    color: #000;
  }

  .text a {
    font-weight: 700;
    color: #4a58dd;
  }
}
</style>
